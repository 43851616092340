import React, {useEffect, useState} from 'react';
import classes from './RawData.module.scss'
import {
    DataGridPremium,
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    useGridApiRef,
    useKeepGroupedColumnsHidden
} from "@mui/x-data-grid-premium";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "@mui/material";
import apiV2 from "../../apiV2";
import moment from "moment";
import MonthPicker from "../Library/MonthPicker/MonthPicker";
import DetailPanelContent from "./DetailPanelContent/DetailPanelContent";
import {getAllUsers} from "../../store/actions/MngData";
import ExpandCircleDownRoundedIcon from '@mui/icons-material/ExpandCircleDownRounded';

const RawData = () => {
    const apiRef = useGridApiRef();
    const dispatch = useDispatch()

    const userList = useSelector(state => state.mng_data.users)

    const [date, setDate] = useState(moment().month(moment().month() - 1).startOf('month'))
    const [workdata, setWorkdata] = useState([])

    useEffect(() => {
        dispatch(getAllUsers())
    }, []);


    const columns = [
        {field: 'day', headerName: 'Day'},
        {
            field: 'user_id',
            headerName: 'Tecnico',
            type: 'string',
            width: 150,
            groupingValueGetter: (params) => {
                const _user = userList?.find(u => u.get('id') === params.row.user_id)
                return `${_user?.get('surname')} ${_user?.get('name')}`
            },
            valueGetter: (params) => userList?.find(u => u.id === params.row.user_id)?.get('name')
        },
        {
            id: '3',
            field: 'x',
            headerName: 'Cliente',
            width: 130,
            valueGetter: (params) => params.row.nc?.substring(0, 4)
        },
        {field: 'nc', headerName: 'Commessa', width: 140},
        {field: 'fdl', headerName: 'FDL'},
        {field: 'place', headerName: 'Luogo', width: 200},
        {field: 'region', headerName: 'Stato', width: 200},
        {field: 'interlocutor', headerName: 'Interlocutore', width: 250},
        {
            field: 'fs', headerName: 'Fs/Trs', width: 120, valueGetter: (params) => {
                if (params.row.fs)
                    return 'FS'
                else if (params.row.trs)
                    return 'TRS'
            }
        },
        {
            field: 'locked', headerName: 'Validazione', width: 200,
            valueGetter: (params) => {
                console.debug(params)
                console.debug(params.row)
                console.debug(params.row.locked)
                if (!params.rowNode.isAutoGenerated)
                    if (params.row.locked === 0 || !params.row.locked)
                        return 'Mai confermato'
                    else if (10 > params.row.locked > 0)
                        return 'Dati modificati'
                    else if (params.row.locked >= 10)
                        return 'Dati Confermati'

            },
            cellClassName: (params) => {
                if (!params.rowNode.isAutoGenerated)
                    if (params.row.locked === 0 || !params.row.locked)
                        return classes.notConfirmed
                    else if (10 > params.row.locked > 0)
                        return classes.edited
                    else if (params.row.locked >= 10)
                        return classes.confirmed
            }
        },
        {
            ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
            headerName: 'Dettagli',
            renderCell: (params) => {
                console.debug(params)
                if (!params.rowNode.isAutoGenerated)
                    return <ExpandCircleDownRoundedIcon style={{marginLeft: '12px'}}/>
                else
                    return <></>
            }
        },

    ];

    const initialState = useKeepGroupedColumnsHidden({
        apiRef,
        initialState: {
            rowGrouping: {
                model: ['user_id', 'day'],
            },
        },
    });

    const getDetailPanelContent = React.useCallback(
        ({row}) => <DetailPanelContent row={row}/>,
        [],
    );

    const getDetailPanelHeight = React.useCallback((params) => {
        console.debug(params)
        if (params.row.travels?.length > 0 && params.row.purchases?.length > 0)
            return 580
        else
            return 330

    }, []);

    return <div className={classes.rawData}>
        <div>
            <MonthPicker
                value={date}
                onChange={(n) => {
                    setDate(n)
                }}
            />
            <Button
                onClick={() => {
                    apiV2.post('statistics/work_data', {date: date.format('DD/MM/YYYY')})
                        .then(res => setWorkdata(res.data))
                }}
            >
                Visualizza
            </Button>
        </div>
        <DataGridPremium
            columns={columns}
            rows={workdata}
            apiRef={apiRef}
            rowGroupingColumnMode="single"
            initialState={initialState}
            getDetailPanelHeight={getDetailPanelHeight}
            getDetailPanelContent={getDetailPanelContent}
            // getCellClassName={(params) => {
            //     if (!params.rowNode.isAutoGenerated)
            //         if (params.row.locked === 0 || !params.row.locked)
            //             return classes.notConfirmed
            //         else if (10 > params.row.locked > 0)
            //             return classes.edited
            //         else if (params.row.locked >= 10)
            //             return classes.confirmed
            // }}

        />
    </div>
}

export default RawData;
