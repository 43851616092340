import {fromJS} from 'immutable';

import * as actionTypes from '../actions/action'
import apiV2 from "../../apiV2";
import {errorNotification, getMonday} from "../utility";
import {enqueueSnackbar} from "./action";

const TableWorkdata = "WorkData";
const TableWorkTime = "WorkTime";
const TableViaggi = "Travels";
const TablePagamenti = "Purchases";
const TableWtTasks = "WtTasks";

export const saveResult = (res) => {
    return {
        type: actionTypes.UPDATE_WEEK,
        day: res
    };
};

export const updateEventWorktime = (data) => {
    return {
        type: actionTypes.UPDATE_EVENT_WORKTIME,
        data: data
    };
};

export const updateState = (res) => {
    return {
        type: actionTypes.UPDATE_STATE,
        day: res
    };
};

export const updateMacchine = (res) => {
    return {
        type: actionTypes.UPDATE_MACHINES_LIST,
        macchine: res
    };
};

export const updateFile = (res) => {
    return {
        type: actionTypes.UPDATE_FILE_STATE,
        data: res
    };
};


//<editor-fold WorkData>

export const getWorkData = (day, bef_days) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            apiV2.get(`workdata?start_date=${getMonday(day).toLocaleDateString('it-IT')}&bef_days=${bef_days}`)
                .then(response => {
                    const res = fromJS(response.data.data)
                    dispatch(saveResult(res))
                    resolve(res)
                })
                .catch((err) => {
                    console.error('workdata', err)
                    dispatch(errorNotification(err, '030105', {400: "Errore dati", 500: "Errore interno"}))
                    reject()
                })
        })
    }
}

export const onWDAdd = (idxDay, idDay, locked = 0, paidLeave = 0) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const user = getState().auth.user.getIn(['userData', 'id'])
            apiV2.post('workdata', [{
                day: idDay,
                user_id: user,
                locked: locked,
                paid_leave: paidLeave,
                worktime: [{user_id: user}]
            }])
                .then(response => {
                    let _newComm = fromJS(response.data.data[0]);

                    let oldState = getState().data.day;
                    dispatch(saveResult(oldState.update(idxDay, day => day.update('workdata', wd => wd.push(_newComm)))))
                    resolve(_newComm)
                })
                .catch(err => {
                    console.error(err)
                    dispatch(errorNotification(err, '030206', {400: "Errore dati", 500: "Errore interno"}))
                    reject()
                })
        })
    }
};

export const onWdPaste = (idxDay, idxComm) => {
    return (dispatch, getState) => {

        let DestinationDay = getState().data.day.getIn([idxDay, 'day'])
        let sourceWorkdata = getState().data.wdCopied
        sourceWorkdata = sourceWorkdata.update('day', () => DestinationDay)
        sourceWorkdata = sourceWorkdata.delete('id')
        sourceWorkdata = sourceWorkdata.delete('last_update')
        sourceWorkdata = sourceWorkdata.delete('task')
        sourceWorkdata = sourceWorkdata.update('locked', () => 0)
        sourceWorkdata = sourceWorkdata.update('worktime', wd => wd.map(w => w.delete('id')))
        sourceWorkdata = sourceWorkdata.update('travels', trv => trv.map(t => t.delete('id')))
        sourceWorkdata = sourceWorkdata.update('purchases', purch => purch.map(p => p.delete('id')))
        sourceWorkdata = sourceWorkdata.update('tasks', task => task.map(t => t.delete('id')))

        apiV2.post('workdata', [sourceWorkdata.toJS()])
            .then(response => {
                let _newComm = fromJS(response.data.data);
                if (idxComm !== undefined) {
                    dispatch(onWorkDataDelete(idxDay, idxComm, getState().data.day.getIn([idxDay, 'workdata', idxComm, 'id'])))
                        .finally(() => {
                            let oldState = getState().data.day;
                            dispatch(saveResult(oldState.update(idxDay, day => day.update('workdata', wd => wd.merge(_newComm)))))
                        })
                } else {
                    let oldState = getState().data.day;
                    dispatch(saveResult(oldState.update(idxDay, day => day.update('workdata', wd => wd.merge(_newComm)))))
                }


            })
            .catch(err => {
                console.error(err)
                dispatch(errorNotification(err, '030206', {400: "Errore dati", 500: "Errore interno"}))
            })
    }
};

export const onWdCopy = (idxDay, idxComm) => {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.UPDATE_COPIED_WD,
            data: getState().data.day.getIn([idxDay, 'workdata', idxComm])
        })
        dispatch(enqueueSnackbar({
            message: 'Dati copiati',
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
                autoHideDuration: 800,
            },
        }))
    }
};

export const weekWorkDataAdd = (data, day) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            apiV2.post('workdata', data.toJS())
                .then(response => {
                    dispatch(getWorkData(day, 7)).then(() => {
                        const wd = getState().data.day
                        wd.forEach((day, wdIndex) => {
                            if (day.get('workdata').size > 1) {
                                day.get('workdata').forEach((w, wIndex) => {
                                    if (!w.get('nc')) dispatch(onWorkDataDelete(wdIndex, wIndex, w.get('id')))
                                })
                            }
                        })
                        resolve()
                    })
                    dispatch(getEventWorktime())
                })
                .catch(err => {
                    dispatch(errorNotification(err, '030104', {}, 'Errore creazione commesse settimanali'))
                    reject()
                })
        })
    }
};

export const onWorkDataDelete = (idxDay, idxComm, idComm) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            apiV2.delete('workdata?id=' + idComm)
                .then(response => {
                    dispatch(saveResult(getState().data.day.update(idxDay, day => day.update('workdata', wd => wd.delete(idxComm)))))
                    resolve()
                })
                .catch(err => {
                    console.error(`/workdata`, err)
                    let message = '030102, Errore eliminazione commessa'
                    dispatch(enqueueSnackbar({
                        message: message,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'error',
                            autoHideDuration: 3000,
                        },
                    }))
                    reject()
                })
            ;
        })
    }
};

export const onWorkDataChange = (value, idxDay, idxComm, propsId, idComm) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            apiV2.put('workdata', {
                table: TableWorkdata,
                id: idComm,
                workdataId: idComm,
                data: {
                    [propsId]: value,
                }
            })
                .then(res => {
                    const data = fromJS(res.data.data)
                    dispatch(saveResult(getState().data.day.updateIn([idxDay, 'workdata', idxComm], val => val.merge(data))))
                    if (getState().data.day.getIn([idxDay, 'workdata', idxComm, 'locked'], 0) >= 10) {
                        dispatch(saveResult(getState().data.day.updateIn([idxDay, 'workdata', idxComm, 'locked'], val => 5)))
                    }
                    // if (propsId === 'nc' && value !== '') {
                    //     dispatch(updateEventWorktime(getState().data.eventWorktime.push(Map({
                    //         id: idxComm,
                    //         day: getState().data.day.getIn([idxDay, 'day']),
                    //         nc: value
                    //     }))))
                    // } else if (propsId === 'nc') {
                    //     const idx = getState().data.eventWorktime.findIndex(ew => ew.get('id') === idxComm)
                    //     dispatch(updateEventWorktime(getState().data.eventWorktime.delete(idx)))
                    // }
                    resolve(data)
                })
                .catch(err => {
                    console.error(`/workdata`, err)
                    let message = '030102, Errore salvataggio dati, riprovare!'
                    if (err.response) {
                        if (err.response.status === 400 &&
                            err.response.data &&
                            err.response.data.detail.includes('is a required property')
                        ) {
                            message = '030103, Campo mancante PUT Aggiornamento dati'
                        }
                    }
                    dispatch(enqueueSnackbar({
                        message: message,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'error',
                            autoHideDuration: 3000,
                        },
                    }))
                })
        })
    }
};


export const onForceEditWorkdata = (wd) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            apiV2.put('forceEditWorkdata', [wd.toJS()]).then(res => {
                const r = fromJS(res.data.data)
                const oldState = getState().data.day
                const idxDay = oldState.findIndex(d => d.get('day') === r.getIn([0, 'day']))
                const idxComm = oldState.getIn([idxDay, 'workdata']).findIndex(d => d.get('id') === r.getIn([0, 'id']))
                dispatch(saveResult(oldState.updateIn([idxDay, 'workdata', idxComm], val => val.merge(r.get(0)))))

                resolve()
            })
        })
    }
}

//</editor-fold>

export const getEventWorktime = () => {
    return (dispatch, getState) => {
        apiV2.get(`eventWorktime`)
            .then(response => dispatch(updateEventWorktime(fromJS(response.data.data))))
    }
}

//<editor-fold WorkTime>
export const onWorkTimeAdd = (idxDay, idxComm, idWd) => {
    return (dispatch, getState) => {
        apiV2.post('worktime', [{wd_id: idWd, user_id: getState().auth.user.getIn(['userData', 'id'])}])
            .then(response => {
                let newWorkTime = fromJS(response.data.data[0]);
                dispatch(saveResult(getState().data.day.updateIn([idxDay, 'workdata', idxComm, 'worktime'], wt => wt.push(newWorkTime))))
            })
            .catch(err => {
                console.error(`/travel`, err)
                let message = '030503 - Errore interno,\nRiprovare!'
                if (err.response) {
                    if (err.response.status === 400 &&
                        err.response.data &&
                        err.response.data.detail.includes('is a required property')
                    ) {
                        message = '030503 - Errore interno'
                    }
                }
                dispatch(enqueueSnackbar({
                        message: message,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'error',
                            autoHideDuration: 3000,
                        },
                    })
                )
            })
    }
};

export const onWorkTimeDelete = (idxDay, idxComm, idxWt, idWt) => {
    return (dispatch, getState) => {
        apiV2.delete(`worktime?id=${idWt}`)
            .then(response => {
                dispatch(saveResult(getState().data.day.updateIn([idxDay, 'workdata', idxComm, 'worktime'], wt => wt.delete(idxWt))))
            })
            .catch(err => {
                console.error(`/worktime`, err)
                dispatch(errorNotification(err, '030502', {}, 'Errore eliminazione Worktime'))
            });
    }
};
export const onWorkTimeChange = (value, idxDay, idxComm, idxWorkTIme, propName, idWorkTIme) => {
    return (dispatch, getState) => {
        const workdataId = getState().data.day.getIn([idxDay, 'workdata', idxComm, 'id'])
        apiV2.put('workdata', {
            table: TableWorkTime,
            id: idWorkTIme,
            workdataId,
            data: {
                [propName]: value,
            }
        })
            .then(res => {
                dispatch(saveResult(getState().data.day.updateIn([idxDay, 'workdata', idxComm, 'worktime', idxWorkTIme, propName], val => value)))
                if (getState().data.day.getIn([idxDay, 'workdata', idxComm, 'locked'], 0) >= 10) {
                    dispatch(saveResult(getState().data.day.updateIn([idxDay, 'workdata', idxComm, 'locked'], val => 5)))
                }
            })
            .catch(err => {
                console.error(`/worktime`, err)
                dispatch(errorNotification(err, '030501', {}, 'Errore modifica Worktime'))
            })
    }
};
//</editor-fold>

//<editor-fold travels>
export const onTrvAdd = (idxDay, idxComm, idDay, idComm) => {
    return (dispatch, getState) => {
        let sendData = {day: idDay, wd_id: idComm, user_id: getState().auth.user.getIn(['userData', 'id'])}
        const lastTrv = getState().data.day.getIn([idxDay, 'workdata', idxComm, 'travels']).last()
        if (lastTrv) {
            sendData.plates = lastTrv.get('plates')
        }
        apiV2.post('travel', [sendData])
            .then(response => {
                let new_travel = fromJS(response.data.data[0]);
                dispatch(saveResult(getState().data.day.updateIn([idxDay, 'workdata', idxComm, 'travels'], travels => travels.push(new_travel))))
            })
            .catch(err => {
                console.error(`/travel`, err)
                let message = '030201 - Errore interno,\nRiprovare!'
                if (err.response) {
                    if (err.response.status === 400 &&
                        err.response.data &&
                        err.response.data.detail.includes('is a required property')
                    ) {
                        message = '030202 - Errore interno'
                    }
                }
                dispatch(enqueueSnackbar({
                        message: message,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'error',
                            autoHideDuration: 3000,
                        },
                    })
                )
            })
    }
};

export const onTrvDelete = (idxDay, idxComm, idxTrav, idDay, idComm, idTrav) => {
    return (dispatch, getState) => {
        apiV2.delete('travel?id=' + idTrav)
            .then(response => {
                dispatch(saveResult(getState().data.day.updateIn([idxDay, 'workdata', idxComm, 'travels'], travels => travels.delete(idxTrav))))

            })
            .catch(err => {
                console.error(`/travel`, err)
                dispatch(errorNotification(err, '030502', {}, 'Errore eliminazione travel'))
            });
    }
};

export const onTrvChangeHandler = (value, idxDay, idxComm, idxTrav, propsId, idDay, idComm, idTravel) => {
    return (dispatch, getState) => {
        const workdataId = getState().data.day.getIn([idxDay, 'workdata', idxComm, 'id'])
        apiV2.put('workdata', {
            table: TableViaggi,
            id: idTravel,
            workdataId,
            data: {
                [propsId]: value,
            }
        })
            .then(response => {
                dispatch(saveResult(getState().data.day.updateIn([idxDay, 'workdata', idxComm, 'travels', idxTrav, propsId], val => value)))
                if (getState().data.day.getIn([idxDay, 'workdata', idxComm, 'locked'], 0) >= 10) {
                    dispatch(saveResult(getState().data.day.updateIn([idxDay, 'workdata', idxComm, 'locked'], val => 5)))
                }
            })
            .catch(err => {
                console.error(`/workdata`, err)
                let message = '030102, Errore salvataggio dati, riprovare!'
                if (err.response) {
                    if (err.response.status === 400 &&
                        err.response.data &&
                        err.response.data.detail.includes('is a required property')
                    ) {
                        message = '030103, Campo mancante PUT Aggiornamento dati'
                    }
                }
                dispatch(enqueueSnackbar({
                    message: message,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                        autoHideDuration: 3000,
                    },
                }))
            });
    }
};

//</editor-fold>

//<editor-fold purchase>
export const onPurchAdd = (idxDay, idxComm, idDay, idComm) => {
    return (dispatch, getState) => {

        apiV2.post('purchase', [{day: idDay, wd_id: idComm, user_id: getState().auth.user.getIn(['userData', 'id']),}])
            .then(response => {
                let new_purchase = fromJS(response.data.data[0]);
                dispatch(saveResult(getState().data.day.updateIn([idxDay, 'workdata', idxComm, 'purchases'], purchases => purchases.push(new_purchase))))
            }).catch(err => {
            console.error(`/purchase`, err)
            let message = '030301 - Errore interno,\nRiprovare!'
            if (err.response) {
                if (err.response.status === 400 &&
                    err.response.data &&
                    err.response.data.detail.includes('is a required property')
                ) {
                    message = '030302 - Errore interno'
                }
            }
            dispatch(enqueueSnackbar({
                    message: message,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                        autoHideDuration: 3000,
                    },
                })
            )
        })
    }
};

export const onPurchDelete = (idxDay, idxComm, idxPurch, idDay, idComm, idPurch) => {
    return (dispatch, getState) => {
        apiV2.delete('purchase?id=' + idPurch)
            .then(response => {
                dispatch(saveResult(getState().data.day.updateIn([idxDay, 'workdata', idxComm, 'purchases'], purchases => purchases.delete(idxPurch))))
            })
            .catch(err => {
                console.error(`/worktime`, err)
                dispatch(errorNotification(err, '030301', {}, 'Errore eliminazione pagamento'))
            });
    }
};

export const onPurchChangeHandler = (value, idxDay, idxComm, idxPurch, propsId, idDay, idComm, idPurch) => {
    return (dispatch, getState) => {
        const workdataId = getState().data.day.getIn([idxDay, 'workdata', idxComm, 'id'])
        apiV2.put('workdata', {
            table: TablePagamenti,
            id: idPurch,
            workdataId,
            data: {
                [propsId]: value,
            }
        })
            .then(response => {
                dispatch(saveResult(getState().data.day.updateIn([idxDay, 'workdata', idxComm, 'purchases', idxPurch, propsId], val => value)))
                if (getState().data.day.getIn([idxDay, 'workdata', idxComm, 'locked'], 0) >= 10) {
                    dispatch(saveResult(getState().data.day.updateIn([idxDay, 'workdata', idxComm, 'locked'], val => 5)))
                }
            })
            .catch(err => {
                console.error(`/worktime`, err)
                dispatch(errorNotification(err, '030301', {}, 'Errore modifica pagamento'))
            });
    }
};
//</editor-fold>

//<editor-fold wttask>
export const onWtTaskAdd = (idxDay, idxWt, wtId) => {
    return (dispatch, getState) => {

        apiV2.post('wttask', {wk_id: wtId})
            .then(response => {
                let new_task = fromJS(response.data.data);
                dispatch(saveResult(getState().data.day.updateIn([idxDay, 'workdata', idxWt, 'tasks'], tasks => tasks.push(new_task))))
            }).catch(err => {
            console.error(`/wttask`, err)
            let message = '040301 - Errore interno,\nRiprovare!'
            if (err.response) {
                if (err.response.status === 400 &&
                    err.response.data &&
                    err.response.data.detail.includes('is a required property')
                ) {
                    message = '040302 - Errore interno'
                }
            }
            dispatch(enqueueSnackbar({
                message: message,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'error',
                    autoHideDuration: 3000,
                },
            }))
        })
    }
};

export const onWtTaskDelete = (idxDay, idxWt, idxWtTask, idWtTask) => {

    return (dispatch, getState) => {
        apiV2.delete('wttask?id=' + idWtTask).then(response => {
            dispatch(saveResult(getState().data.day.updateIn([idxDay, 'workdata', idxWt, 'tasks'], purchases => purchases.delete(idxWtTask))))
        });
    }
};

export const onWtTaskChangeHandler = (value, idxDay, idxWt, idxWtTask, propsId, idWtTask) => {
    return (dispatch, getState) => {
        const workdataId = getState().data.day.getIn([idxDay, 'workdata', idxWt, 'id'])
        apiV2.put('workdata',
            {
                table: TableWtTasks,
                id: idWtTask,
                workdataId,
                data: {
                    [propsId]: value,
                }
            }
        ).then(response => {
            dispatch(saveResult(getState().data.day.updateIn([idxDay, 'workdata', idxWt, 'tasks', idxWtTask, propsId], val => value)))
            if (getState().data.day.getIn([idxDay, 'workdata', idxWt, 'locked'], 0) >= 10) {
                dispatch(saveResult(getState().data.day.updateIn([idxDay, 'workdata', idxWt, 'locked'], val => 5)))
            }
        });
    }
};
//</editor-fold>

//<editor-fold print-fdl>
export const generateFdl = (days, commissions, fdl, fdlDescription, generateFdl, generatePurchase, expAddData, force, lockData) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            apiV2.post('printFdl', {
                days,
                commissions,
                fdl,
                fdlDescription,
                generateFdl,
                generatePurchase,
                expAddData,
                force,
                lockData
            })
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    if (err.response && err.response.status === 418) {
                        reject({
                            data: fromJS(err.response.data.data),
                            days,
                            commissions,
                            fdl,
                            fdlDescription,
                            generateFdl,
                            generatePurchase,
                            expAddData,
                            lockData
                        })
                    } else {
                        console.error(`/printFdl`, err)
                        dispatch(errorNotification(err, '030601', {}, 'Errore generazione fdl'))
                        reject(false)
                    }
                })
            // apiV2.post('lockData', {days, commissions, force})
            // .then(res => resolve(res))
            // .catch(err => {
            //     if (err.response && err.response.status === 418) {
            //         reject({
            //             data: fromJS(err.response.data.data),
            //             days,
            //             commissions,
            //             fdlDescription,
            //             generateFdl,
            //             generatePurchase,
            //             expAddData
            //         })
            //     } else {
            //         console.error(`/printFdl`, err)
            //         dispatch(errorNotification(err, '030601', {}, 'Errore generazione fdl'))
            //         reject(false)
            //     }
            // })
        })
    }
}

export const lockDay = (day, force) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            apiV2.post('lockData', {days: [day], force})
                .then(res => resolve(res))
                .catch(err => {
                    if (err.response && err.response.status === 418) {
                        reject({
                            data: err.response.data.data,
                        })
                    } else {
                        console.error(`/lockData`, err)
                        dispatch(errorNotification(err, '', {}, 'Errore blocco dati'))
                        reject(false)
                    }
                })
        })
    }
}

export const generateCleanPdf = (type) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            apiV2.post(type === 0 ? 'printCleanFdl' : 'printCleanSpese',)
                .then(res => resolve(res))
                .catch(err => {
                    if (err.response && err.response.status === 418) {
                        reject()
                    } else {
                        console.error(type === 1 ? 'printCleanFdl' : 'printCleanSpese', err)
                        dispatch(errorNotification(err, '030601', {}, 'Errore generazione pdf vuoto'))
                        reject()
                    }
                })
        })
    }
}


export const downloadFdl = (fileName) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            apiV2({
                url: `printFdl?fdl_name=${fileName}`,
                method: 'GET',
                responseType: 'blob',
            })
                .then(res => resolve(res))
                .catch(err => {
                    console.error(`/printFdl`, err)
                    dispatch(errorNotification(err, '030602', {}, 'Errore recupero fdl'))
                    reject(err)
                })
        })
    }
}
//</editor-fold>

//<editor-fold user-FDL>

export const getUserFdl = () => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const _date = new Date();
            const firstDay = new Date(_date.getFullYear(), _date.getMonth(), 1);

            apiV2.post(`fdls`, {
                technician: [getState().auth.user.getIn(['userData', 'id'])],
                from_emission: firstDay.toLocaleDateString()
            })
                .then(response => {
                    dispatch({
                        type: actionTypes.UPDATE_FDLS,
                        data: fromJS(response.data.data)
                    })
                    resolve()
                })
                .catch((err) => {
                    console.error('fdls', err)
                    // dispatch(errorNotification(err, '030105', {400: "Errore dati", 500: "Errore interno"}))
                    reject()
                })
        })
    }
}
//</editor-fold>
